<template>
  <div>
    <div class="flix-form-group flix-text-right">
      <transition name="flixFadeIn">
        <div :key="open">
          <a v-if="!open" href="#" class="flix-html-a flix-text-danger" @click.prevent="open = !open"><flixIcon :id="'bin'" /> {{ $tc('message.appointmentRequests', 1) }} {{ $t('message.delete') }}</a>
          <div v-if="open" class="flix-html-small flix-text-danger"><flixIcon :id="'warning-sign'" /> {{ $t('message.allDataWillBeDeleted') }}<br>{{ $t('message.delete') }}: <a href="#" class="flix-btn flix-btn-xs flix-btn-default" @click.prevent="setDelete()">{{ $t('message.yes') }}</a> | <a href="#" class="flix-btn flix-btn-xs flix-btn-default" @click.prevent="open = !open">{{ $t('message.no') }}</a></div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    data: Object,
    saveCalendar: Function
  },
  data () {
    return {
      open: false
    }
  },
  methods: {
    setDelete () {
      var send = {
        user: this.$route.params.user,
        ID: this.data.ID,
        event_id: this.data.form
      }
      this.$flix_post({
        url: '/booking/delete',
        data: send,
        callback: function (ret) { this.saveCalendar() }.bind(this)
      })
    }
  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
